import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./sidebar.scss";
import HomeIcon from "@mui/icons-material/Home";

import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import StyleIcon from "@mui/icons-material/Style";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { logout } from "state/calls";

import { Message } from "@mui/icons-material";
const Sidebar = () => {
  const dispatch1 = useDispatch();
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <div className={"sidebar"}>
      <div className="top">
        <span>KADET CLINIC</span>
        <div className="expand">
          <KeyboardArrowRightRoundedIcon onClick={showSidebar} />
        </div>
      </div>

      <div className="body">
        <ul>
          <p className="title">Accueil</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <HomeIcon className="icon" />
              <span>Accueil</span>
            </li>
          </Link>

          <hr />

          <p className="title">Listes</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PeopleAltIcon className="icon" />
              <span>Utilisateurs</span>
            </li>
          </Link>

          <Link to="/messages" style={{ textDecoration: "none" }}>
            <li>
              <Message className="icon" />
              <span>Messages</span>
            </li>
          </Link>

          <Link to="/reservations" style={{ textDecoration: "none" }}>
            <li>
              <StyleIcon className="icon" />
              <span>Reservations</span>
            </li>
          </Link>

          <hr />

          <li onClick={() => dispatch1(logout)}>
            <LogoutIcon className="icon" />
            <span>Sortir</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;

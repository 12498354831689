import React from 'react'
import "./chart.scss"
import { LineChart, Area, XAxis, CartesianGrid, Tooltip, ResponsiveContainer, YAxis, Line } from 'recharts';

const data = [
  { name: "Jan", Total: 1200,uv: 2040, pv: 2400, amt: 2400 },
  { name: "Fev", Total: 2100 ,uv: 1200, pv: 2400, amt: 2400},
  { name: "Mars", Total: 800 ,uv: 2400, pv: 2400, amt: 2400},
  { name: "Avr", Total: 1600,uv: 350, pv: 2400, amt: 2400 },
  { name: "Mai", Total: 900,uv: 80, pv: 2400, amt: 2400 },
  { name: "Juin", Total: 1700 , uv: 180, pv: 2400, amt: 2400},
];


const Chart = ({aspect}) => {
  return (
    <div className='chartone' >
    <div className="title"></div>
    <ResponsiveContainer width="98%" aspect={aspect}>
      <LineChart width={600} height={190} data={data} margin={{ top: 40, right: 60, left: 0, bottom: 40 }}>
        <Line type="monotone" dataKey="Total" stroke="#00695C" />
        <Line type="monotone" dataKey="uv" stroke="#00838F"/>
        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" className='chartGrid'/>
        <XAxis dataKey="name" stroke='gray' />
        <YAxis />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  </div>
  )
}

export default Chart
import React, { useEffect, useState } from "react";
import "./newuser.scss";
import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import { setUser } from "state/calls";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import { useForm } from "react-hook-form";
import { baseRequest } from "utils/requestmethods";

const Newuser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [values, setValues] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setfileName] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (file) setfileName(Date.now() + file.name);
  }, [file]);

  useEffect(() => {
    setData({ ...data, logo: fileName });
  }, [fileName]);

  const handleInput = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
    console.log(data);
  };

  const handleSub = async (event) => {
    if (file) {
      const data1 = new FormData();
      //const fileName = Date.now() + file.name
      data1.append("name", fileName);
      data1.append("file", file);
      //setData({...data, "logo":fileName});
      console.log(data);
      try {
        await baseRequest.post("/upload", data1);
      } catch (err) {}
    }

    try {
      setUser(data, dispatch);
      navigate(-1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleImage = (e) => {
    setFile(e.target.files[0]);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="newuser">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Nouveau Utlisateur</h1>
        </div>
        <div className="bottom">
          <div className="left"></div>
          <div className="right">
            <form onSubmit={handleSubmit(handleSub)}>
              <div className="formInput">
                <TextField
                  {...register("username", { required: true })}
                  id="username"
                  label="Nom d'utilisateur"
                  variant="outlined"
                  onChange={handleInput}
                />
                {errors.username && <p className="error">Champ Oblégatoire</p>}
              </div>
              <div className="formInput">
                <FormControl>
                  <InputLabel htmlFor="password">Mot de passe</InputLabel>
                  <OutlinedInput
                    {...register("password", { required: true })}
                    id="password"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleInput}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {errors.password && <p className="error">Champ Oblégatoire</p>}
              </div>

              <div className="formInput">
                <TextField
                  {...register("firstname", { required: true })}
                  id="firstname"
                  label="Prénom"
                  variant="outlined"
                  onChange={handleInput}
                />
                {errors.firstname && <p className="error">Champ Oblégatoire</p>}
              </div>

              <div className="formInput">
                <TextField
                  {...register("lastname", { required: true })}
                  id="lastname"
                  label="Nom"
                  variant="outlined"
                  onChange={handleInput}
                />
                {errors.lastname && <p className="error">Champ Oblégatoire</p>}
              </div>
              <div className="formInput">
                <TextField
                  {...register("phone1", { required: true })}
                  id="phone1"
                  label="Téléphone 1"
                  variant="outlined"
                  onChange={handleInput}
                />
                {errors.phone1 && <p className="error">Champ Oblégatoire</p>}
              </div>
              <div className="formInput">
                <TextField
                  {...register("phone2", { required: true })}
                  id="phone2"
                  label="Téléphone 2"
                  variant="outlined"
                  onChange={handleInput}
                />
                {errors.phone2 && <p className="error">Champ Oblégatoire</p>}
              </div>

              <div className="formInput">
                <TextField
                  {...register("email", { required: true })}
                  id="email"
                  label="E-mail"
                  variant="outlined"
                  onChange={handleInput}
                />
                {errors.email && <p className="error">Champ Oblégatoire</p>}
              </div>
              <div className="formInput">
                <button className="submit">Ajouter</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newuser;

import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import Chart from "components/chart/Chart";
import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import "./singlereservation.scss";

const Singlereservation = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const itemId = location.pathname.split("/")[2];
  const reservationInfo = useSelector((state) =>
    state.reservation.reservations.find(
      (reservation) => reservation._id === itemId
    )
  );

  return (
    <div className="singlereservation">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">DETAILS</h1>
            <div className="item">
              <div className="details">
                <div className="detailItem">
                  <span className="itemKey">Nom complet :</span>
                  <span className="valueKey">{reservationInfo.fullname}</span>
                </div>

                <div className="detailItem">
                  <span className="itemKey">Téléphone : </span>
                  <span className="valueKey">
                    {reservationInfo.prefix} {reservationInfo.phone}
                  </span>
                </div>

                <div className="detailItem">
                  <span className="itemKey">Text : </span>
                  <span className="valueKey">{reservationInfo.text}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Singlereservation;

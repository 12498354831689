import { imageRequest } from "./requestmethods";

export const userColumns = [
  {
    field: "username",
    headerName: "Utilisateur",
    width: 230,
  },
  {
    field: "phone1",
    headerName: "Téléphone 1",
    width: 230,
  },

  {
    field: "phone2",
    headerName: "Téléphone 2",
    width: 200,
  },
];

export const messageColumns = [
  {
    field: "fullname",
    headerName: "Nom complet",
    width: 230,
  },

  {
    field: "prefix",
    headerName: "Préfix",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Téléphone",
    width: 200,
  },

  {
    field: "text",
    headerName: "Message",
    width: 200,
  },
];

export const reservationColumns = [
  {
    field: "fullname",
    headerName: "Nom complet",
    width: 230,
  },

  {
    field: "prefix",
    headerName: "Préfix",
    width: 230,
  },

  {
    field: "phone",
    headerName: "Téléphone",
    width: 200,
  },

  {
    field: "text",
    headerName: "Message",
    width: 200,
  },
];

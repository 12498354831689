import React, { useState } from "react";
import "./login.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { login } from "../../state/calls";
import image from "images/background.jpg";
import { useForm } from "react-hook-form";

const Login = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = useState("");
  const dispatch = useDispatch();
  const { isFetching, error } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user.currentUser);

  const handleClick = () => {
    //e.preventDefault();
    login(dispatch, { username, password });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login">
      <div className="container">
        <div className="right">
          <div className="login">
            <span>Login</span>
            <form onSubmit={handleSubmit(handleClick)}>
              <div className="formInput">
                <TextField
                  {...register("username", { required: true })}
                  id="username"
                  label="Nom d;utilisateur"
                  variant="outlined"
                  onChange={(e) => setUsername(e.target.value)}
                />
                {errors.username && <p className="error">Champ Oblégatoire</p>}
              </div>

              <div className="formInput">
                <FormControl>
                  <InputLabel htmlFor="password">Mot de passe</InputLabel>
                  <OutlinedInput
                    {...register("password", { required: true })}
                    id="password"
                    label="Mot de passe"
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={(e) => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {errors.password && <p className="error">Champ Oblégatoire</p>}
              </div>

              <button className="submit" type="submit">
                Entrer
              </button>
            </form>
          </div>
        </div>

        <div className="left">
          <img src={image} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;

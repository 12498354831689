import React, { useContext } from 'react'
import './navbar.scss'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { useSelector } from 'react-redux';
import { imageRequest } from 'utils/requestmethods';

const Navbar = () => {

  const userInfo = useSelector((state) => state.user.currentUser.details);
 
  return (
    <div className='navbar'>
      <div className="warpper">
       
         <div className="item">
            Bonjour : {userInfo.firstname} {userInfo.lastname}
          </div>
      </div>
    </div>
  )
}

export default Navbar
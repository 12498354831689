import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    users: [],
    succeed: false,
    isFetching: false,
    error: false,
  },

  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    loginSuccess: (state, action) => {
      state.isFetching = false;
      state.error = false;
      state.currentUser = action.payload;
    },
    loginfaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logoutSuccess: (state) => {
      state.currentUser = null;
    },

    userStart: (state) => {
      state.isFetching = true;
    },

    userSuccess: (state, action) => {
      state.isFetching = false;
      state.users = action.payload;
    },

    userFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    setUserStart: (state) => {
      state.isFetching = true;
    },

    setUserSuccess: (state, action) => {
      state.isFetching = false;
      state.users.push(action.payload);
    },

    setUserFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    updateUserStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.succeed = false;
    },

    updateUserSuccess: (state, action) => {
      state.succeed = true;
      state.isFetching = false;
      state.users[
        state.users.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.users;
    },

    updateUserFaild: (state) => {
      state.succeed = false;
      state.isFetching = false;
      state.error = true;
    },

    deleteUserStart: (state) => {
      state.isFetching = true;
    },

    deleteUserSuccess: (state, action) => {
      state.isFetching = false;
      state.users.splice(
        state.users.findIndex((item) => item._id === action.payload.id),
        1
      );
    },

    deleteUserFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginfaild,
  logoutSuccess,
  userStart,
  userSuccess,
  userFaild,
  setUserStart,
  setUserSuccess,
  setUserFaild,
  updateUserStart,
  updateUserSuccess,
  updateUserFaild,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFaild,
} = userSlice.actions;
export default userSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const reservationSlice = createSlice({
  name: "reservation",
  initialState: {
    reservations: [],
    succeed: false,
    isFetching: false,
    error: false,
  },
  reducers: {
    reservationStart: (state) => {
      state.isFetching = true;
    },

    reservationSuccess: (state, action) => {
      state.isFetching = false;
      state.reservations = action.payload;
    },

    reservationFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    setReservationStart: (state) => {
      state.isFetching = true;
    },

    setReservationSuccess: (state, action) => {
      state.isFetching = false;
      state.reservations.push(action.payload);
    },

    setReservationFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    updateReservationStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.succeed = false;
    },

    updateReservationSuccess: (state, action) => {
      state.succeed = true;
      state.isFetching = false;
      state.reservations[
        state.reservations.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.reservations;
    },

    updateReservationFaild: (state) => {
      state.succeed = false;
      state.isFetching = false;
      state.error = true;
    },

    deleteReservationStart: (state) => {
      state.isFetching = true;
    },

    deleteReservationSuccess: (state, action) => {
      state.isFetching = false;
      state.reservations.splice(
        state.reservations.findIndex((item) => item._id === action.payload.id),
        1
      );
    },

    deleteReservationFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  reservationStart,
  reservationSuccess,
  reservationFaild,
  setReservationStart,
  setReservationSuccess,
  setReservationFaild,
  updateReservationStart,
  updateReservationSuccess,
  updateReservationFaild,
  deleteReservationStart,
  deleteReservationSuccess,
  deleteReservationFaild,
} = reservationSlice.actions;
export default reservationSlice.reducer;

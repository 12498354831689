import React, { useEffect, useState } from "react";
import { DataGrid, frFR, GridToolbar } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  userColumns,
  messageColumns,
  reservationColumns,
} from "utils/datatable";
import {
  deleteMessage,
  deleteReservation,
  deleteUser,
  getMessages,
  getReservations,
  getUsers,
} from "state/calls";
import "./datatable.scss";
import { baseRequest } from "utils/requestmethods";

const Datatable = () => {
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const location = useLocation();
  const itemId = location.pathname.split("/")[1];
  const users = useSelector((state) => state.user.users);

  const messages = useSelector((state) => state.message.messages);

  const reservations = useSelector((state) => state.reservation.reservations);

  const { succeed, isFetching, error } = useSelector(
    (state) => state.user.users
  );

  useEffect(() => {
    switch (itemId) {
      case "users":
        {
          setColumn(userColumns);
          getUsers(dispatch);

          setTitle("Utilisateurs");
        }
        break;

      case "messages":
        {
          setColumn(messageColumns);
          getMessages(dispatch);

          setTitle("Messages");
        }
        break;

      case "reservations":
        {
          setColumn(reservationColumns);
          getReservations(dispatch);

          setTitle("Reservations");
        }
        break;
    }
  }, [itemId]);

  useEffect(() => {
    switch (itemId) {
      case "users":
        {
          setData(users);
        }
        break;
      case "messages":
        {
          setData(messages);
        }
        break;

      case "reservations":
        {
          setData(reservations);
        }
        break;
    }
  }, [itemId, users, messages, reservations]);

  const handleDelete = async (id) => {
    switch (itemId) {
      case "users":
        {
          const res = await baseRequest.delete(`/users/${id}`);
          getUsers(dispatch);
          if (res.data.success) {
            alert(res.data.msg);
          }
          setData(users);
        }
        break;

      case "messages":
        {
          const res = await baseRequest.delete(`/messages/${id}`);
          getMessages(dispatch);
          if (res.data.success) {
            alert(res.data.msg);
          }

          setData(messages);
        }
        break;

      case "reservations":
        {
          const res = await baseRequest.delete(`/reservations/${id}`);
          getReservations(dispatch);
          if (res.data.success) {
            alert(res.data.msg);
          }
          setData(reservations);
        }
        break;
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Options",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link to={params.row._id} style={{ textDecoration: "none" }}>
              <div className="viewButton">Details</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleDelete(params.row._id)}
            >
              Supprimer
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        {title}
        {title === "Utilisateurs" && (
          <Link to={"/" + itemId + "/new"} className="link">
            Nouveau
          </Link>
        )}
      </div>

      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        className="dataGrid"
        rows={data}
        getRowId={(row) => row._id}
        columns={column.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        disableSelectionOnClick
        {...data}
        loading={isFetching}
        components={{
          Toolbar: GridToolbar,
        }}
        componentsProps={{
          toolbar: { printOptions: { disableToolbarButton: true } },
        }}
      />
    </div>
  );
};

export default Datatable;

import { baseRequest } from "utils/requestmethods";

import {
  loginStart,
  loginSuccess,
  loginfaild,
  logoutSuccess,
  userStart,
  userSuccess,
  userFaild,
  setUserStart,
  setUserSuccess,
  setUserFaild,
  updateUserStart,
  updateUserSuccess,
  updateUserFaild,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFaild,
} from "./userRedux";
import {
  messageStart,
  messageSuccess,
  messageFaild,
  deleteMessageStart,
  deleteMessageSuccess,
  deleteMessageFaild,
} from "./messageRedux";

import {
  reservationStart,
  reservationSuccess,
  reservationFaild,
  deleteReservationStart,
  deleteReservationSuccess,
  deleteReservationFaild,
} from "./reservationRedux";

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await baseRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginfaild());
  }
};

export const logout = async (dispatch) => {
  dispatch(logoutSuccess());
};

export const setUser = async (data, dispatch) => {
  dispatch(setUserStart());
  try {
    const res = await baseRequest.post("/auth/register", data);
    dispatch(setUserSuccess(res.data));
  } catch (err) {
    dispatch(setUserFaild());
  }
};

export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    const res = await baseRequest.delete(`/users/${id}`);
    dispatch(deleteUserSuccess(res.data));
  } catch (err) {
    dispatch(deleteUserFaild());
  }
};

export const updateUser = async (id, data, dispatch) => {
  dispatch(updateUserStart());
  try {
    const res = await baseRequest.put(`/users/${id}`, data);
    dispatch(updateUserSuccess(res.data));
  } catch (err) {
    dispatch(updateUserFaild());
  }
};

export const getUsers = async (dispatch) => {
  dispatch(userStart());
  try {
    const res = await baseRequest.get("/users");
    dispatch(userSuccess(res.data));
  } catch (err) {
    dispatch(userFaild());
  }
};

//

export const deleteMessage = async (id, dispatch) => {
  dispatch(deleteMessageStart());
  try {
    const res = await baseRequest.delete(`/messages/${id}`);
    dispatch(deleteMessageSuccess(res.data));
  } catch (err) {
    dispatch(deleteMessageFaild());
  }
};

export const getMessages = async (dispatch) => {
  dispatch(messageStart());
  try {
    const res = await baseRequest.get("/messages");
    dispatch(messageSuccess(res.data));
  } catch (err) {
    dispatch(messageFaild());
  }
};

//

export const deleteReservation = async (id, dispatch) => {
  dispatch(deleteReservationStart());
  try {
    const res = await baseRequest.delete(`/reservations/${id}`);
    dispatch(deleteReservationSuccess(res.data));
  } catch (err) {
    dispatch(deleteReservationFaild());
  }
};

export const getReservations = async (dispatch) => {
  dispatch(reservationStart());
  try {
    const res = await baseRequest.get("/reservations");
    dispatch(reservationSuccess(res.data));
  } catch (err) {
    dispatch(reservationFaild());
  }
};

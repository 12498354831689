import { createSlice } from "@reduxjs/toolkit";

const messageSlice = createSlice({
  name: "message",
  initialState: {
    messages: [],
    succeed: false,
    isFetching: false,
    error: false,
  },
  reducers: {
    messageStart: (state) => {
      state.isFetching = true;
    },

    messageSuccess: (state, action) => {
      state.isFetching = false;
      state.messages = action.payload;
    },

    messageFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    setMessageStart: (state) => {
      state.isFetching = true;
    },

    setMessageSuccess: (state, action) => {
      state.isFetching = false;
      state.messages.push(action.payload);
    },

    setMessageFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    updateMessageStart: (state) => {
      state.isFetching = true;
      state.error = false;
      state.succeed = false;
    },

    updateMessageSuccess: (state, action) => {
      state.succeed = true;
      state.isFetching = false;
      state.users[
        state.messages.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.messages;
    },

    updateMessageFaild: (state) => {
      state.succeed = false;
      state.isFetching = false;
      state.error = true;
    },

    deleteMessageStart: (state) => {
      state.isFetching = true;
    },

    deleteMessageSuccess: (state, action) => {
      state.isFetching = false;
      state.messages.splice(
        state.messages.findIndex((item) => item._id === action.payload.id),
        1
      );
    },

    deleteMessageFaild: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  messageStart,
  messageSuccess,
  messageFaild,
  deleteMessageStart,
  deleteMessageSuccess,
  deleteMessageFaild,
} = messageSlice.actions;
export default messageSlice.reducer;

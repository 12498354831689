import './App.css';
import Home from 'pages/home/Home';

import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import List from 'pages/list/List';
import Singleuser from 'pages/single/singleuser/Singleuser';

import Newuser from 'pages/new/newuser/Newuser';
import Singlemessage from 'pages/single/singlemessage/Singlemessage';
import Login from 'pages/login/Login';
import Singlereservation from 'pages/single/singlereservation/Singlereservation';

function App() {
  const user = useSelector(state => state.user.currentUser)

  return (
    (!user ? 
      <Login/>
    :
    <div className="App">
      <Routes>
        <Route exact path="/">
          <Route index element={ <Home/>}/>
            <Route path="users">
                      <Route index element={<List/>} />
                      <Route path=":id" element={<Singleuser/>} />
                      <Route path="new" element={<Newuser/>}/>
            </Route>

            <Route path="messages">
                      <Route index element={<List/>} />
                      <Route path=":id" element={<Singlemessage/>} />

            </Route>

            <Route path="reservations">
                      <Route index element={<List/>} />
                      <Route path=":id" element={<Singlereservation/>} />

            </Route>
        </Route>
      </Routes>  
    </div>
    )
    )
}

export default App;


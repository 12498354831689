import React, { useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import Chart from 'components/chart/Chart'
import Navbar from 'components/navbar/Navbar'
import Sidebar from 'components/sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getUsers, updateUser } from 'state/calls'
import './singleuser.scss'

const Singleuser = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const itemId = location.pathname.split("/")[2];
    const userInfo = useSelector((state) =>
    state.user.users.find((user) => user._id === itemId)
    );
    const {succeed} = useSelector((state) => state.user);
    const [data, setData] = useState({});
    const [checked, setChecked] = useState(true);

    useEffect(()=>{
      dispatch(getUsers)
    },[succeed])

    useEffect(()=>{
      if(userInfo.status){
        setChecked(true)

      }else{
        setChecked(false)
      
      }
    },[])

    const handleChange =  (e) => {
      const id = e.target.id;
      const value = e.target.value;
      setData({...data, [id]:value});
  
      console.log(data);
      
    }

    const handleStatus = (event) => {
      setChecked(event.target.checked);
      
      if(checked === false){
        updateUser(itemId,{"status":"true"},dispatch);
      }else{  
        updateUser(itemId,{"status":"false"},dispatch);
      }
    }

  return (
    <div className='singleuser'>
    <Sidebar/>
    <div className="singleContainer">
      <Navbar />
      <div className="top">

        <div className="left">
          
          <h1 className="title">DETAILS</h1>
          <div className="item">
           
            <div className="details">
            <div className="detailItem">
               <span className="itemKey">Utilisateur :</span> 
               <span className="valueKey"> {userInfo.username} </span>
              </div>
              <div className="detailItem">
                <span className="itemKey">Prénom : </span>
                <span className="valueKey">{userInfo.firstname}</span>
              </div>

              <div className="detailItem">
                <span className="itemKey">Nom : </span>
                <span className="valueKey">{userInfo.lastname}</span>
              </div>

              
              <div className="detailItem">
                <span className="itemKey">Téléphone 1 : </span>
                <span className="valueKey">{userInfo.phone1}</span>
              </div>
              <div className="detailItem">
                <span className="itemKey">Téléphone 2 : </span>
                <span className="valueKey">{userInfo.phone2}</span>
              </div>

              <div className="detailItem">
                <span className="itemKey">E-mail : </span>
                <span className="valueKey">{userInfo.email}</span>
              </div>

              <div className="detailItem">
                <span className="itemKey">Admin : </span>
                <span className="valueKey">{userInfo.isAdmin ? "Oui" : "Non"}</span>
              </div>

             

            </div>
          </div>
        </div>
        <div className="right">
         <Chart aspect={1.5 /1} title="Dernier 6 Mois (Spending)"/>
        </div>
      </div>
      <div className="bottom">
        
        
      </div>
    </div>
  </div>
  )
}

export default Singleuser
import React, { useEffect, useState } from 'react'
import { Switch } from '@mui/material'
import Chart from 'components/chart/Chart'
import Navbar from 'components/navbar/Navbar'
import Sidebar from 'components/sidebar/Sidebar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getUsers, updateUser } from 'state/calls'
import './singlemessage.scss'

const Singlemessage = () => {
  const dispatch = useDispatch();
    const location = useLocation();
    const itemId = location.pathname.split("/")[2];
    const messageInfo = useSelector((state) =>
    state.message.messages.find((message) => message._id === itemId)
    );


  return (
    <div className='singlemessage'>
    <Sidebar/>
    <div className="singleContainer">
      <Navbar />
      <div className="top">

        <div className="left">
          
          <h1 className="title">DETAILS</h1>
          <div className="item">
           
            <div className="details">
            <div className="detailItem">
               <span className="itemKey">Nom Complet :</span> 
               <span className="valueKey"> {messageInfo.fullname} </span>
              </div>

              <div className="detailItem">
                <span className="itemKey">Telephone : </span>
                <span className="valueKey">{messageInfo.prefix} {messageInfo.phone}</span>
              </div>

              <div className="detailItemMessage">
                <span className="itemKey">Message : </span>
                <span className="valueKey">{messageInfo.text}</span>
              </div>

            
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  )
}

export default Singlemessage
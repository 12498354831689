import Chart from "components/chart/Chart";
import Featured from "components/featured/Featured";
import Navbar from "components/navbar/Navbar";
import Sidebar from "components/sidebar/Sidebar";
import TableList from "components/table/Table";
import Widget from "components/widget/Widget";
import React from "react";
import "./home.scss";
import image from "images/background.jpg";
const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="container">
        <Navbar />
        <div className="row1">
          <div className="image">
            <img src={image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
